import React, { Component } from "react";
import { Card, CardBody, Container, Row, Col, TabContent, TabPane, Label, FormGroup,Collapse } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from "../../assets/images/site-logo.png";
import uuidValidate from 'uuid-validate'; 
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import axios from "axios";
class ProjectWorksheetThank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WorkheetStatus: ''
    };
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const leadId = params.worksheetId;
    const isValid = uuidValidate(leadId,4);
    if (!isValid) {
        this.props.history.push('/');
    }  
    axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectCampaignLead/fullbyid/${leadId}`)
    .then(res => {
      const WorkheetStatus = res.data;
      this.setState({ WorkheetStatus });
    })
    .catch(error => {
      // Handle error
      console.error("There was an error fetching the data!", error);
    });
  }
  render() {
    const { WorkheetStatus } = this.state;
    console.log(WorkheetStatus);
    return (
     
      <React.Fragment>
       
       <div className="headrtop">
          <div className="toppart">
            <Row>
              <Col sm="3">
                <div className="d-flex flex-wrap gap-2">
                  <img src={logo} alt="" height="70px" />
                </div>
              </Col>
             
              <Col xl="3">
                <h3 className="text-center mt-3">
                 
                </h3>
              </Col>
            
              <Col sm="6">
                <Row>
                  <Col sm="4">
                    <h4 className="agentInfo"><strong>Agent</strong><br />
                    <span>{WorkheetStatus?.agent?.firstName+" "+WorkheetStatus?.agent?.lastName	}</span>
                    </h4>
                  </Col>
                  <Col sm="4">
                  <h4 className="agentInfo"><strong>Phone</strong><br />
                    <span>{WorkheetStatus?.agent?.agentContact?.cContact?.phone }</span>
                    </h4>
                  </Col>
                  <Col sm="4">
                  <h4 className="agentInfo"><strong>Email</strong><br />
                    <span>{WorkheetStatus?.agent?.primaryEmail	 }</span>
                    </h4>
                  </Col>
                </Row>


              </Col>
            </Row>
          </div>
        </div>
        <div className="page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
           
                <Row>
                  <Col xl="3"></Col>
                 
                   <Col sm="6" className="text-center ">
                   <h4 className="text-danger">Thank you </h4>
                   
                   <h5>Thank you for submitting the form. </h5>
                   <h5>
                     We will Contact you shortly to go over the next steps. If
                     you have questions feel free to call at {WorkheetStatus?.agent?.agentContact?.cContact?.phone } or
                     Email at {WorkheetStatus?.agent?.primaryEmail	 }{" "}
                   </h5>
                   <hr className="mt-4" />
                  
                 </Col>
             
                </Row>
             
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
ProjectWorksheetThank.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history:PropTypes.object
  
};
export default connect()(withTranslation()(ProjectWorksheetThank));

